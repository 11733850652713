import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Blog from "./Pages/Blog";
import WorkExperience from "./Pages/Experience";
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import {
  GITHUB,
  LINKEDIN,
  RESUME,
  ZOOM,
  NOTION_DSA_PLAN,
  CALENDLY,
  MEME,
  NOTION_INTERVIEW_PLAN,
  JOB_TRACKER,
  TRANSCRIPT,
} from "./Utils/Constants";

export default function App() {
  const [shouldRender, setShouldRender] = React.useState(false);

  useEffect(() => {
    console.log(window.location.pathname);
    switch (window.location.pathname) {
      case "/resume":
        window.location.href = RESUME;
        break;
      case "/transcript":
        window.location.href = TRANSCRIPT;
        break;
      case "/linkedin":
        window.location.href = LINKEDIN;
        break;
      case "/github":
        window.location.href = GITHUB;
        break;
      case "/plan":
        window.location.href = NOTION_DSA_PLAN;
        break;
      case "/zoom":
        window.location.href = ZOOM;
        break;
      case "/chat":
        window.location.href = CALENDLY;
        break;
      case "/meme":
        window.location.href = MEME;
        break;
      case "/notion":
        window.location.href = NOTION_INTERVIEW_PLAN;
        break;
      case "/tracker":
        window.location.href = JOB_TRACKER;
        break;
      default:
        setShouldRender(true);
        break;
    }
  }, []);

  if (!shouldRender) return null;

  return (
    <Router>
      <div className="flex justify-center min-h-screen bg-background">
        <div className="w-full max-w-3xl px-4 md:px-8">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/experience" element={<WorkExperience />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/blog" element={<Blog />} />
            {/* Add more routes as needed */}
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

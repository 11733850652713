import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import React from 'react';
import { workExperiences } from '../Utils/Experience';

const WorkExperienceTimeline = () => {
  return (
    <Timeline position='alternate'>
      {workExperiences.map((experience, index) => (
        <TimelineItem key={index}>
          {/* duration */}
          <TimelineOppositeContent
            sx={{ m: 'auto 0', width: '200px' }} // Increase the width here to make lines longer
            align='right'
            variant='body2'
            color='#737373'
          >
            {experience.duration}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot></TimelineDot>
            {index < workExperiences.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            {/* role */}
            <Typography variant='h7' component='span' style={{ color: '#fff' }}>
              {experience.role}
            </Typography>
            {/* company */}
            <Typography style={{ color: '#B7B9BC' }}>
              {experience.company}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default WorkExperienceTimeline;

import DescriptionIcon from "@mui/icons-material/Description";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Masonry from "@mui/lab/Masonry";
import imageArray from "../Assets/ImageArray";
// eslint-disable-next-line
import { GITHUB, LINKEDIN, RESUME } from "../Utils/Constants";

export default function Home() {
  return (
    <div className="animate-fadeIn">
      {/* Heading section */}
      <div>
        <h1 className="py-5 mb-4 text-2xl font-bold text-primary">
          hey, I'm Nafis 👋
        </h1>
      </div>

      {/* Introduction paragraph */}
      <div>
        <p className="leading-8 text-md text-regular text-primary">
          Originally from Bangladesh and currently based out of New York City, I
          am a Computer Science graduate from The City College Of New York,
          passionate about Software Engineering. I thrive on building robust and
          scalable applications that solve real-world problems. My journey in
          tech has taken me from startups to big tech companies, providing a
          rich tapestry of experiences.
        </p>
      </div>

      {/* Image gallery or showcase */}
      <div className="flex items-center justify-center mt-6 mb-5">
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
          {imageArray.map((image, index) => (
            <div key={index}>
              <img
                className="rounded-lg"
                src={image.src}
                alt={image.caption}
                style={{
                  width: "100%",
                  display: "block",
                  maxHeight: "300px",
                  objectFit: "cover",
                }} // Added maxHeight and objectFit
                loading="lazy"
              />
            </div>
          ))}
        </Masonry>
      </div>

      {/* Personal background information */}
      <div>
        <p className="mt-4 leading-8 text-md text-regular text-primary">
          I have contributed to projects ranging from building machine learning
          models for research to enhancing interactive voice response systems at
          UPS. My work at Godaddy has improved the performance and security of
          hosting and proxy services, showcasing my ability to deliver results
          in high-stakes environments. My goal is to become a confident senior
          software engineer and eventually build my own company.
        </p>
      </div>

      {/* Social and project cards */}
      <div className="flex flex-wrap items-stretch justify-center gap-4 my-20">
        {/* LinkedIn Card */}
        <a
          href={LINKEDIN}
          target="_blank"
          className="flex w-full overflow-hidden transition-all rounded-lg md:w-auto hover:shadow-lg hover:bg-darkGray"
          style={{ maxWidth: "300px" }} // Set a maximum width in pixels
          rel="noreferrer"
        >
          <div className="flex items-center justify-center w-1/3">
            <LinkedInIcon style={{ fontSize: 40, color: "white" }} />
          </div>
          <div className="w-2/3 p-4">
            <h3 className="font-semibold text-md text-primary">LinkedIn</h3>
            <p className="text-sm text-primary">Connect with me</p>
          </div>
          ``
        </a>

        {/* GitHub Card */}
        <a
          href={GITHUB}
          target="_blank"
          className="flex w-full overflow-hidden transition-all rounded-lg md:w-auto hover:shadow-lg hover:bg-darkGray"
          style={{ maxWidth: "300px" }} // Set a maximum width in pixels
          rel="noreferrer"
        >
          <div className="flex items-center justify-center w-1/3">
            <GitHubIcon style={{ fontSize: 40, color: "white" }} />
          </div>
          <div className="w-2/3 p-4">
            <h3 className="font-semibold text-md text-primary">GitHub</h3>
            <p className="text-sm text-primary">Check out my projects</p>
          </div>
        </a>

        {/* Resume Card */}
        <a
          href={RESUME}
          target="_blank"
          className="flex w-full overflow-hidden transition-all rounded-lg md:w-auto hover:shadow-lg hover:bg-darkGray"
          style={{ maxWidth: "300px" }} // Set a maximum width in pixels
          rel="noreferrer"
        >
          <div className="flex items-center justify-center w-1/3">
            <DescriptionIcon style={{ fontSize: 40, color: "white" }} />
          </div>
          <div className="w-2/3 p-4">
            <h3 className="font-semibold text-md text-primary">Resume</h3>
            <p className="text-sm text-primary">Look at my resume</p>
          </div>
        </a>
      </div>
    </div>
  );
}

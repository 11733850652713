import image1 from './1.webp';
import image2 from './2.webp';
import image3 from './3.webp';
import image4 from './4.webp';
import image5 from './5.webp';
import image6 from './6.webp';

const imageArray = [
  {
    src: image4,
    thumbnail: image4,
    thumbnailWidth: 320, // Set the width as needed
    thumbnailHeight: 100, // Set the height as needed
    isSelected: false,
    caption: 'Caption for image 1', // Optional caption
  },
  {
    src: image5,
    thumbnail: image5,
    thumbnailWidth: 320, // Set the width as needed
    thumbnailHeight: 174, // Set the height as needed
    isSelected: false,
    caption: 'Caption for image 1', // Optional caption
  },
  {
    src: image2,
    thumbnail: image2,
    thumbnailWidth: 300, // Different width for this image
    thumbnailHeight: 200, // Different height for this image
    isSelected: false,
    caption: 'Caption for image 2', // Optional caption
  },
  {
    src: image1,
    thumbnail: image1,
    thumbnailWidth: 300, // Different width for this image
    thumbnailHeight: 200, // Different height for this image
    isSelected: false,
    caption: 'Caption for image 2', // Optional caption
  },
  {
    src: image6,
    thumbnail: image6,
    thumbnailWidth: 300, // Different width for this image
    thumbnailHeight: 200, // Different height for this image
    isSelected: false,
    caption: 'Caption for image 2', // Optional caption
  },
  {
    src: image3,
    thumbnail: image3,
    thumbnailWidth: 320, // Set the width as needed
    thumbnailHeight: 174, // Set the height as needed
    isSelected: false,
    caption: 'Caption for image 1', // Optional caption
  },
];

export default imageArray;

import React from 'react';

function Footer() {
  return (
    <footer className='p-4 my-20'>
      <div className='flex flex-col items-center justify-center text-xs'>
        <p className='text-primary'>
          © {new Date().getFullYear()} Nafis Khan. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';

import WorkExperienceTimeline from '../Components/WorkExperienceTimeline';

function WorkExperience() {
  return (
    <div className='animate-fadeIn'>
      <div>
        <h1 className='py-5 mb-4 text-2xl font-bold text-primary'>
          work experience
        </h1>
      </div>
      <WorkExperienceTimeline />
      {/* Blog content goes here */}
    </div>
  );
}

export default WorkExperience;

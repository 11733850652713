import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { GITHUB_REPOS } from '../Utils/Constants';

// Custom loading component
function CustomLoadingOverlay() {
  return (
    <div
      style={{
        // position: 'absolute',
        top: '100%',
        left: '100%',
        transform: 'translate(50%, 50%)',
      }}
    >
      <CircularProgress style={{ color: '#fff' }} />
    </div>
  );
}

export default function Projects() {
  const [repos, setRepos] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const token = process.env.REACT_APP_GITHUB_TOKEN;
    const headers = {
      Authorization: `token ${token}`,
    };

    const fetchCommitCounts = async repoData => {
      const repoWithCommits = await Promise.all(
        repoData.map(async repo => {
          const commitUrl = `https://api.github.com/repos/${repo.full_name}/stats/contributors`;
          try {
            const commitResponse = await fetch(commitUrl, { headers });
            if (!commitResponse.ok) {
              throw new Error(`HTTP error! status: ${commitResponse.status}`);
            }
            const commitData = await commitResponse.json();

            // Check if commitData is an array and has data
            if (Array.isArray(commitData) && commitData.length > 0) {
              const commitCount = commitData.reduce(
                (acc, contributor) => acc + contributor.total,
                0
              );
              return { ...repo, commitCount };
            } else {
              // Handle cases where commit data is not in the expected format
              console.log(
                `Commit data not in expected format for repo: ${repo.name}`
              );
              return { ...repo, commitCount: 0 }; // Default to 0 if data is not available
            }
          } catch (error) {
            console.error(
              `Error fetching commit data for repo: ${repo.name}`,
              error
            );
            return { ...repo, commitCount: 0 }; // Default to 0 in case of error
          }
        })
      );
      return repoWithCommits;
    };

    fetch(GITHUB_REPOS, { headers })
      .then(response => response.json())
      .then(async data => {
        const reposWithCommits = await fetchCommitCounts(data);
        const formattedData = reposWithCommits.map(repo => ({
          id: repo.id,
          name: repo.name,
          size: repo.size, // Size in kilobytes
          commitCount: repo.commitCount,
          html_url: repo.html_url,
        }));
        setRepos(formattedData);
        setLoading(false);
      })
      .catch(error => console.error(error));
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Project Name',
      flex: 1,
      renderCell: params => (
        <div style={{ paddingLeft: '10px' }}>{params.value}</div>
      ),
    },
    {
      field: 'size',
      headerName: 'Size (MB)',
      flex: 0.5,
      valueGetter: params => `${(params.row.size / 1024).toFixed(2)} MB`,
    },
    {
      field: 'commitCount',
      headerName: 'Commits',
      flex: 0.5,
    },
    {
      field: 'html_url',
      headerName: 'Link',
      flex: 0.5,
      renderCell: params => (
        <a
          href={params.value}
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: '#fff' }}
        >
          View <ArrowOutwardIcon />
        </a>
      ),
    },
  ];

  const sortModel = [
    {
      field: 'commitCount',
      sort: 'desc',
    },
  ];

  return (
    <div style={{ height: 1200, width: '100%', marginBottom: '200px' }}>
      <div>
        <h1 className='py-5 mb-4 text-2xl font-bold text-primary'>
          all github repositories
        </h1>
      </div>
      <DataGrid
        rows={repos}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        pagination={false}
        sortModel={sortModel}
        hideFooter={true}
        loading={loading} // Set loading state
        components={{
          LoadingOverlay: CustomLoadingOverlay, // Use custom loading overlay
        }}
        sx={{
          '& .MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
            color: '#fff',
          },
          '& .MuiDataGrid-virtualScroller': {
            '&::-webkit-scrollbar': {
              backgroundColor: '#737373',
              width: '8px', // Make scrollbar thinner
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#1a1a1a', // Make scrollbar almost black
              borderRadius: '8px',
            },
          },
        }}
      />
    </div>
  );
}

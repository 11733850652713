export const workExperiences = [
  {
    company: "CodePath",
    role: "Tech Fellow Manager",
    duration: "July 2024 - Present",
    location: "New York, United States · Remote",
  },
  {
    company: "CodePath",
    role: "Tech Fellow",
    duration: "Mar 2024 - July 2024",
    location: "New York, United States · Remote",
  },
  {
    company: "Google",
    role: "G-SWEP Fellow",
    duration: "Oct 2023 - June 2024",
    location: "New York, United States · Remote",
  },
  {
    company: "GoDaddy",
    role: "Software Engineering Intern",
    duration: "Jun 2023 - Sep 2023",
    location: "New York, United States · Remote",
  },
  {
    company: "ColorStack",
    role: "Software Engineering Fellow",
    duration: "Aug 2023",
    location: "Remote",
  },
  {
    company: "Bloomberg",
    role: "Bloomberg Accelerator Summer School",
    duration: "Jun 2023 - Jul 2023",
    location: "Remote",
  },
  {
    company: "Google",
    role: "Google ExploreCSR - Undergraduate ML Researcher",
    duration: "Mar 2023 - Jul 2023",
    location: "New York, United States · Remote",
  },
  {
    company: "BASTA",
    role: "Career Coach",
    duration: "Mar 2023 - Jul 2023",
    location: "Remote",
  },
  {
    company: "BASTA",
    role: "Basta Fellow",
    duration: "Dec 2022 - Mar 2023",
    location: "Greater New York Area",
  },
  {
    company: "CUNY Tech Prep",
    role: "Software Engineering Fellow",
    duration: "Jun 2022 - Jun 2023",
    location: "New York, United States",
  },
  {
    company: "UPS",
    role: "Software Engineer Intern",
    duration: "Jul 2022 - Mar 2023",
    location: "Remote",
  },
  {
    company: "Headstarter",
    role: "Ambassador",
    duration: "Aug 2022 - Feb 2023",
    location: "Remote",
  },
  {
    company: "Headstarter",
    role: "Software Engineering Fellow",
    duration: "Jun 2022 - Aug 2022",
    location: "New York, United States",
  },
  {
    company: "AT&T",
    role: "Technology Academy Apprentice",
    duration: "Jul 2022 - Aug 2022",
    location: "Remote",
  },
  {
    company: "Accenture",
    role: "Diversity Tech Externship",
    duration: "Jul 2022",
    location: "Remote",
  },
  {
    company: "RF CUNY",
    role: "Computer Science Teaching Assistant",
    duration: "Oct 2020 - Jul 2022",
    location: "Manhattan, New York, United States",
  },
  {
    company: "Microsoft",
    role: "Tech Resilience Program Apprentice",
    duration: "Feb 2022 - Apr 2022",
    location: "Remote",
  },
  {
    company: "CodePath",
    role: "Android Development Teaching Assistant",
    duration: "Aug 2021 - Jan 2022",
    location: "Remote",
  },
  {
    company: "Meta",
    role: "Meta University Intern Lead",
    duration: "June 2021 - Aug 2021",
    location: "Remote",
  },
];

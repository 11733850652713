export const LINKEDIN = "https://www.linkedin.com/in/nafisrk/";
export const GITHUB = "https://github.com/nafisk";
export const RESUME =
  "https://drive.google.com/file/d/1dXE3F4hqFwnrDsMTCuj_2esjXwUDJe0W/view?usp=sharing";
export const TRANSCRIPT =
  "https://drive.google.com/file/d/1d3f2MWyButmUBMvC7Udh3UhLLGyI7kjS/view?usp=sharing";
export const GITHUB_REPOS =
  "https://api.github.com/users/nafisk/repos?per_page=100";
export const ZOOM = "https://ccny.zoom.us/my/nafisk";
export const NOTION_DSA_PLAN =
  "https://nafisk.notion.site/Data-Structure-Reference-e0140f21ff4f4858ae7310788261edba?pvs=4";
export const CALENDLY = "https://calendly.com/nafis_rk";
export const MEME = "https://youtu.be/dQw4w9WgXcQ?si=CbWYutkFJUOjHV9u";
export const NOTION_INTERVIEW_PLAN =
  "https://nafisk.notion.site/Interview-Preparation-574f546820df40f4af1f72c7cd2b6adf?pvs=4";
export const JOB_TRACKER =
  "https://docs.google.com/spreadsheets/d/1pXaKPmPbnwaXFZCCf6uyWKpGMzJbe2_C0d8o-eldRiw/edit?gid=0#gid=0";

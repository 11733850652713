import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Make sure to have the CSS file for styles

export default function Navbar() {
  return (
    <nav className='py-14 text-secondary'>
      <div className='container flex items-center justify-between mx-auto'>
        <ul className='relative flex space-x-4 text-m'>
          {/* ADD BACK '/blog' */}
          {['/', '/experience', '/projects'].map(path => (
            <li key={path}>
              <NavLink
                to={path}
                className={({ isActive }) =>
                  `nav-link ${isActive ? 'active' : ''}`
                }
                end
              >
                {path === '/' ? 'home' : path.substring(1)}
                <span className='underline'></span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default function Blog() {
  return (
    <div className='animate-fadeIn'>
      <div>
        <h1 className='py-5 mb-4 text-2xl font-bold text-primary'>
          Blog Page under construction 🏗️ 🚧
        </h1>
      </div>
      {/* Blog content goes here */}
    </div>
  );
}
